import React from "react";
import "./index.less";
import ba from "@/assets/images/备案图标.png";
const Mallki = () => {
  return (
    <div className="copyright">
      <div> Copyright © 2024 江苏中商碳素研究院有限公司 备案号：<a href="https://beian.miit.gov.cn" target="_blank" rel="noopener noreferrer">苏ICP备16063958号-10</a></div>
      <div className="beian">
        <img src={ba} alt="" style={{width:'18px',height:'18px',marginRight:'5px'}}/>
        <a href="https://beian.mps.gov.cn/#/query/webSearch?code=32032202000556" rel="noreferrer" target="_blank">苏公网安备32032202000556</a>
        </div>
    </div>
  );
};


export default Mallki; 